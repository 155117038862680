import {isEmail} from 'validator'
import _ from 'lodash'
import React from 'react'
import {secureStorage} from './secure_storage_helper'
import { ENV } from 'index'

let btcLimit = 0.5
export const initValidationHelper = () => {
    if(secureStorage.getItem('role') === 'ADMIN') {
    btcLimit = 2500
    } 
}
export let btc_max = btcLimit;
//export let btc_min = 0.00005460;
export let btc_min = 0.00001; 
export let fiat_min = 0.5;
export let fiat_max = 100000;

function ValidationObject(value, props) {
    this.value = value;
    this.props = props;
}
ValidationObject.prototype = {
    xySum2: function() {
        return this.value + this.props;
    },
    handleOptionals : function() {
        if(this.props['data-optional'] === 'true' && this.value === '') {
            return;
        }
    }
};

export const required = (value, props) => {
    if (!value || (props.isCheckable && !props.checked)) {
        return <span className='form-error is-visible'>Required field</span>
    }
}
export const email = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        if (!isEmail(value)) {
            return <span className='form-error is-visible'>Invalid email</span>
        }
    }
}
export const text = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    }	else {
        let alert = <span className='form-error is-visible'>Invalid {props['data-name']}</span>;
        let patt = new RegExp('^[a-zA-Z0-9 ]{2,}$');
        let res = patt.test(value);
        if (!res) {
	       return alert
        }
    }
}
export const textName = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Invalid {props['data-name']}</span>;
        let patt = new RegExp('^[^/%]{2,}$');
        let res = patt.test(value);
        if (!res) {
            return alert
        }
    }
}
export const key = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Invalid {props['data-name']}. Use only letters, numbers and underscore.</span>;
        let patt = new RegExp('^[a-zA-Z0-9_]{3,}$');
        let res = patt.test(value);
        if (!res) {
            return alert
        }
    }
}
export const swift = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Invalid {props['data-name']}</span>;
        let patt = new RegExp('^([a-zA-Z]){6}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$');
        let res = patt.test(value);
        if (!res) {
            return alert
        }
    }
}
export const account = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Invalid {props['data-name']}</span>;
        let patt = new RegExp('^(?=(?:[ \-]?[a-zA-Z0-9]){9,30}$)((?:[ \-]?[a-zA-Z0-9]{3,5}){2,7})([ \-]?[a-zA-Z0-9]{1,3})?$');
        let res = patt.test(value);
        if (!res) {
            return alert
        }
    }
}
export const iban = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Invalid {props['data-name']}</span>;
        let patt = new RegExp('^([a-zA-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[a-zA-Z0-9]){9,30}$)((?:[ \-]?[a-zA-Z0-9]{3,5}){2,7})([ \-]?[a-zA-Z0-9]{1,3})?$');
        let res = patt.test(value);
        if (!res) {
            return alert
        }
    }
}
export const maxlength = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Too long {props['data-name']} (max {props['data-maxlength']} chars)</span>;
        let maxlength = props['data-maxlength'];
        if(maxlength) {
            let g = parseInt(maxlength,10);
            if (value.length > g) {
                return alert
		      }
        } else {
            return
        }
    }
}
export const minlength = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Too short {props['data-name']} (max {props['data-minlength']} chars)</span>;
        let minlength = props['data-minlength'];
        if(minlength) {
            let g = parseInt(minlength,10);
            if (value.length < g) {
                return alert
              }
        } else {
            return
        }
    }
}

export const bitcoin = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let valueString = value;
        if(typeof value === 'number') {
            valueString = valueString.toString();
        }
        let val = parseFloat(value);
        let alert = <span className='form-error is-visible'>Invalid amount of BTC</span>;
        if(isNaN(val)) {
            return alert
        }
        let patt = new RegExp(/^\d{0,8}(\.\d{0,8})?$/);
        let res = patt.test(value);
        if (!res || val === 0 || valueString.slice(-1) === '.' || valueString.slice(-1) === ',') {
            return alert
        }
    }
}

export const fiat = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let val = parseFloat(value);
        let patt;
        if(props['data-long'] === 'true') {
            patt = new RegExp(/^\d{0,8}(\.\d{0,10})?$/);
        } else {
            patt = new RegExp(/^\d{0,8}(\.\d{0,2})?$/);
        }
        let alert = <span className='form-error is-visible'>Invalid amount of currency</span>;
        if(isNaN(val)) {
            return alert
        }
        let res = patt.test(value);
        let valString = value.toString();
        if (!res || val === 0 || valString.slice(-1) === '.' || valString.slice(-1) === ',') {
	       return alert
        }
	}
}

export const number = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let val = parseFloat(value);
        let precisionSetting = props['data-precision'] ? props['data-precision'] : 8;
        let currencyName = props['data-currency'] ? props['data-currency'] : '';
        let patt = new RegExp('^\\d{0,8}(\\.\\d{0,' + precisionSetting + '})?$');
        let alert = <span className='form-error is-visible'>Invalid amount of currency</span>;
        let alertPrecision = <span className='form-error is-visible'>Max precision for currency {currencyName} is {precisionSetting}</span>;
        if(isNaN(val)) {
            return alert
        }
        let res = patt.test(value);
        let valString = value.toString();
        if (val === 0 || valString.slice(-1) === '.' || valString.slice(-1) === ',') {
            return alert
        }
        if (!res) {
            return alertPrecision
        }
    }
}

export const percent = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    }	else {
        let val = parseFloat(value);
        let alert = <span className='form-error is-visible'>Invalid percentage</span>;
        if(isNaN(val)) {
            return alert
        }
        let patt = new RegExp(/^\d{0,3}(\.\d{0,2})?$/);
        let res = patt.test(value);
        if (!res || value.slice(-1) === '.' || value.slice(-1) === ',' || (val > 100 && props['data-percent-limit'] !== false )) {
            return alert
        }
	}
}

export const percentFee = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    }	else {
        let val = parseFloat(value);
        let alert = <span className='form-error is-visible'>Invalid percentage</span>;
        if(isNaN(val)) {
            return alert
        }
        let patt = new RegExp(/^\d{0,3}(\.\d{0,4})?$/);
        let res = patt.test(value);
        if (!res || value.slice(-1) === '.' || value.slice(-1) === ',' || (val > 100 && props['data-percent-limit'] !== false )) {
            return alert
        }
	}
}

export const max10 = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Max value is 10%</span>;
        if(parseFloat(value) > 10) {
            return alert
        } else {
            return
        }
    }
}
export const maxBtc = (value,props) => {
    if(props['data-optional'] === 'true' && value === '' || props['data-usertype'] === 'ADMIN') {
        return;
    } else {
        let limit = props['data-max'];
        let alert = <span className='form-error is-visible'>Max value is {limit} BTC</span>;
        if(parseFloat(value) > parseFloat(limit)) {
            return alert
        } else {
            return
        }
    }
}
export const maxValue = (value,props) => {
    if(props['data-optional'] === 'true' && value === '' || props['data-usertype'] === 'ADMIN') {
        return;
    } else {
        let limit = props['data-max'];
        let currency = props['data-currency'];
        let alert = <span className='form-error is-visible'>Max value is {limit.formatted ? limit.formatted : limit} {currency}</span>;
        if(parseFloat(value) > parseFloat(limit.value ? limit.value : limit)) {
            return alert
        } else {
            return
        }
    }
}

export const minFiat = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let limit = fiat_min;
        let alert = <span className='form-error is-visible'>Min value is {limit}</span>;
        if(parseFloat(value) < parseFloat(limit)) {
            return alert
        } else {
            return
        }
    }
}

export const minBTC = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Min value is {btc_min} BTC</span>;
        if(parseFloat(value) < parseFloat(btc_min)) {
            return alert
        } else {
            return
        }
    }
}
export const minValue = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let min = props['data-min'];
        let curr = props['data-currency'];
        let minLabel = props['data-min-label'];
        let alert = <span className='form-error is-visible'>Min value is {minLabel ? minLabel : min}</span>;
        if(curr) {
            alert = <span className='form-error is-visible'>Min value is {minLabel ? minLabel : min} {curr}</span>;
        }
        if(parseFloat(value) < parseFloat(min)) {
            return alert
        } else {
            return
        }
    }
}
export const maxFx = (value,props) => {
    if(props['data-optional'] === 'true' && _.isEmpty(value) || props['data-max'] === false || props['data-usertype'] === 'ADMIN') {
        return;
    } else {
        let max = props['data-max'];
        let percent = '';
        max = parseFloat(max,10);
        if(props['data-percent'] !== false) {
            percent = '%';
        }
        let alert = <span className='form-error is-visible'>Max value is {max}{percent}</span>;
        if(props['data-payout'] === true) {
            if(parseFloat(max) < 0) {
                alert = <span className='form-error is-visible'>Payouts are blocked because balance is below 0</span>;
            }
        }
        if(parseFloat(value) > parseFloat(max)) {
            return alert
        } else {
            return
        }
    }
}
export const newName = (label,props) => {
    let alert = <span className='form-error is-visible'>This name is already taken!</span>;
    let search = label.toLowerCase().trim();
    let result = _.find(props.names, function (location) {
        return location.name.toLowerCase() === search;
    });
    if(result !== undefined) {
    	return alert;
    }
}
export const newKeyName = (name,props) => {
    let alert = <span className='form-error is-visible'>This name is already taken!</span>;
    let search = name.toLowerCase().trim();
    let result = _.find(props.names, function (location) {
        return location.name.toLowerCase() === search;
    });
    if(result !== undefined) {
      return alert;
    }
}
export const newEmail = (email,props) => {
    let alert = <span className='form-error is-visible'>This email is already taken!</span>;
    let search = email.toLowerCase().trim();
    let result = _.find(props.names.data, function (location) {
        return location.email.toLowerCase() === search.toLowerCase();
    });
    if(result !== undefined) {
        return alert;
    }
}

export const btcaddress = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        if(props['data-partial-possible'] === true) {
            return
        }
    	let alert = <span className='form-error is-visible'>Invalid BTC address</span>;
        let isTest = ENV.IS_TEST;
        //let isTest = false
        let res = props['data-valid-address'];
    	if (!res) {
    	    return alert
    	} else {
            if(isTest === 'true' && res.testnet === false) {
                return alert
            } 
            if(isTest !== 'true' && res.testnet === true) {
                return alert
            } 
        }
    }
}

export const coinaddress = (value, props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        if(props['data-partial-possible'] === true) {
            return
        }
        let coin = props['data-coin'];
        let alert = <span className='form-error is-visible'>Invalid {coin} address</span>;
        let isTest = ENV.IS_TEST;
        //let isTest = false
        let res = props['data-valid-address'];
        if (!res) {
            return alert
        } else {
            if(isTest === 'true' && res.testnet === false) {
                return alert
            } 
            if(isTest !== 'true' && res.testnet === true) {
                return alert
            } 
        }
    }
}

export const address = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        if(props['data-partial-possible'] === true) {
            return
        }
        let alert = <span className='form-error is-visible'>Invalid address</span>;
        let isTest = ENV.IS_TEST;
        //let isTest = false
        let res = props['data-valid-address'];
        if (!res) {
            return alert
        } else {
            if(isTest === 'true' && res.testnet === false) {
                return alert
            } 
            if(isTest !== 'true' && res.testnet === true) {
                return alert
            } 
        }
    }
}
export const max4d = (value,props) => {
    let alert = <span className='form-error is-visible'>Invalid value</span>;
    let patt = new RegExp(/^[\d]{1,4}$/);
    let res = patt.test(value);
    let val = parseFloat(value);
    if (!res || val === 0) {
        return alert
    }
}

export const btctxid = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        if(props['data-partial-possible'] === true) {
            return
        }
        let alert = <span className='form-error is-visible'>Invalid BTC transaction ID</span>;
        let patt = new RegExp(/^[\d\w]{64}$/);
        let res = patt.test(value);
        if (!res) {
	       return alert
        }
	}
}
export const paymentid = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return
    } else {
        if(props['data-partial-possible'] === true) {
            return
        }
	   let alert = <span className='form-error is-visible'>Invalid ID</span>;
	   let patt = new RegExp(/^[a-fA-F0-9]{64}$/);
	   let res = patt.test(value);
	   if (!res) {
	       return alert
        }
	}
}
export const linkUrl = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return;
    } else {
        let alert = <span className='form-error is-visible'>Invalid url address</span>;
        let patt = new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);
        let res = patt.test(value);
        if (!res) {
            return alert
        }
    }
}
export const uuid = (value,props) => {
    if(props['data-optional'] === 'true' && value === '') {
        return
    } else {
        if(props['data-partial-possible'] === true) {
            return
        }
       let alert = <span className='form-error is-visible'>Invalid ID</span>;
       //let patt = new RegExp(/^[a-fA-F0-9]{64}$/);
       let patt = new RegExp(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/);
       let res = patt.test(value);
       if (!res) {
           return alert
        }
    }
}
