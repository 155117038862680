import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import React, { useContext, useState, useEffect } from 'react'
import variables from 'styles/_variables.scss'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import $ from 'jquery'
import PropTypes from 'prop-types'
import { putMerchantFees } from 'actions/merchantFees'
import { required, percent, minValue, maxFx, percentFee } from 'helpers/validator_helper'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import { feeMultiplied, feeDecimalNumber } from 'helpers/fees_helper'
import ModalContainer from 'components/ui_elements/modal_container'

const TiersWrapper = styled.div`
    .tier-group {
        display: flex;

    }
    .tier-label {
        width: 45px;
        margin-bottom: 0;
        line-height: 40px;
    }
    .tier-group-item {
        width: 114px;
        position: relative;
        &.from {
            text-align: right;
            input {
                text-align: right;
            }
        }
    }
    .separate {
        text-align: center;
        width: 20px;
        padding-top: 10px;
    }
    .text {
        padding: 10px 8px;
        display: block;
    }
    .fa {
        padding: 10px 0 0 5px;
        cursor: pointer;
    }
    .form-error {
        left: 0;
        margin-top: 1px;
    }
    .is-invalid-input {
        border-color: ${variables.defaultDanger};
    }
`

const EditFeeModal = ({feesValues, formSent, onModalClosed, showOnInit, currencyCode}) => {
    const dispatch = useDispatch();
    const merchant = useSelector(state => state.merchant);
    const updatedMerchantFees = useSelector(state => state.updatedMerchantFees);
    const [reload, setReload] = useState(false);
    const feeTiersDefault = [{}];
    const defaultValues = {
        fxFeeTiers: feeTiersDefault,
        payoutFxFeeTiers: feeTiersDefault,
        payoutNetworkFeeTiers: feeTiersDefault,
        conversionFeeTiers: feeTiersDefault
    }
    const [formValues, setFormValues] = useState({...defaultValues, ...feesValues});
    let fxFeeTiersMap = formValues.fxFeeTiers ? formValues.fxFeeTiers : feeTiersDefault;
    let payoutFxFeeTiersMap = formValues.payoutFxFeeTiers && formValues.payoutFxFeeTiers.length ? formValues.payoutFxFeeTiers : feeTiersDefault;
    let payoutNetworkFeeTiersMap = formValues.payoutNetworkFeeTiers && formValues.payoutNetworkFeeTiers.length ? formValues.payoutNetworkFeeTiers : feeTiersDefault;
    let conversionFeeTiersMap = formValues.conversionFeeTiers && formValues.conversionFeeTiers.length ? formValues.conversionFeeTiers : feeTiersDefault;
    const [disabledForm, setDisabledForm] = useState(false);
    useEffect(() => {
        if(updatedMerchantFees === 200 || updatedMerchantFees === 'error') {
            formSent(updatedMerchantFees);
            $('#feeModal').modal('hide');
        }
    }, [updatedMerchantFees]);
    useEffect(() => {
        if(reload) {
            setReload(false);
        }
    }, [reload])

    const handleModalHide = () => {
        onModalClosed();
    }
    const handleChange = (event) => {
        if(event.target.value) {
            setFormValues({...formValues,  [event.target.id]: feeDecimalNumber(event.target.value) });
        }
    }
    const handleSubmit = (event, val) => {
        event.preventDefault();
        handleModalFormSubmitHook({
            event: event, 
            setDisabledForm: setDisabledForm, 
            sendFunction: () => dispatch(putMerchantFees({
                merchantId: merchant.id,
                data: {
                    currencyCode: currencyCode,
                    ...defaultValues,
                    ...formValues
                }
            }))
        });
    }
    const handleFromInput = (event, ind) => {
        if(event.target.value !== undefined) {
            let val = event.target.value !== '' ? parseFloat(event.target.value) : event.target.value;
            setFormValues({
                ...formValues,
                [event.target.id]: formValues[event.target.id].map((item, i) => 
                    i === ind 
                    ? {...item, from: val}
                    : i === ind - 1 ? {...item, to: val}
                    : item 
            )});
        }
    }
    const handleTierInput = (event, ind) => {
        if(event.target.value !== undefined) {
            let val = event.target.value !== '' ? feeDecimalNumber(event.target.value) : event.target.value;
            let values = formValues[event.target.id];
            setFormValues({
                ...formValues,
                [event.target.id]: values.length ? (values.map((item, i) => 
                    i === ind 
                    ? {...item, fee: val}
                    : item 
                )) : [{fee: val}]
            });
        }
    }
    const handleAddTier = (tiersName, e) => {
        e.preventDefault(e);
        setFormValues({...formValues,
            [tiersName]: [...formValues[tiersName], {
                fee: 0
            }]
        })
    }
    const handleDeleteTier = (tiersName, i) => {
        setFormValues({...formValues,
            [tiersName]: formValues[tiersName].map((tier,ind) => {
                    if(ind === i - 1) {
                        return {
                                from: tier.from,
                                to: formValues[tiersName][i].to,
                                fee: tier.fee
                        }
                    }
                    else {
                        return tier
                    }
                
            }).filter((tier, ind) => ind !== i)
        });
        setReload(true);
    }
    return (
    	<ModalContainer thisId='feeModal' title={`Edit ${currencyCode} fees`} onHide={handleModalHide} showInit={showOnInit}>
            <Form onSubmit={handleSubmit} id='editFeeForm' className='basic-form'>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className='mrg-btm-20'>Payment fees</h4>
                        <label className="width-100" htmlFor='fxFeeTier0'>FX fee tiers: * </label>
                        <TiersWrapper className='no-mrg mrg-btm-10 mrg-top-5 mrg-left-15'>
                            {fxFeeTiersMap.map((tier, i) => (
                                <div className='row pdd-right-15 no-pdd-sm-screen' key={`fxFeeTiersMap${i}`}>
                                    <label className='mrg-right-15 tier-label'>Tier {i+1}: </label>
                                    {!reload && <div className='empty tier-group-item from'>{i === 0 ? <span className='text'>0</span> : <Input data-max={fxFeeTiersMap[i+1] && fxFeeTiersMap[i-1].from ? parseFloat(fxFeeTiersMap[i+1].from) + 1 : undefined} data-min={fxFeeTiersMap[i-1] && fxFeeTiersMap[i-1].from ? parseFloat(fxFeeTiersMap[i-1].from) + 1 : undefined}  data-percent={false} type='text' className='form-control number' id={`fxFeeTiers`} name={`fxFeeTiers[${i}].from`} value={tier.from !== 0 ? tier.from : 0} validations={[required, minValue]} onBlur={(e) => handleFromInput(e, i)}  />}</div>}
                                    <span className='separate'>-</span>
                                    <div className='empty tier-group-item'>{i === formValues.fxFeeTiers.length -1 ? <span className='text'>&#8734;</span> : <span className='text'>{tier.to} EUR</span>}</div>
                                    <span className='separate'></span>
                                    <div className='empty tier-group-item form-group'><Input type='text' className='form-control number percent' id={`fxFeeTiers`} name={`fxFeeTiers`} validations={[required, percentFee, minValue]} data-min={0}  value={tier.fee ? feeMultiplied(tier.fee) : tier.fee === 0 ? feeMultiplied(0) : ''} onBlur={(e) => handleTierInput(e, i)} /><i className='percents-icon top-0'>%</i></div>
                                    {i !== 0 && <i onClick={() => handleDeleteTier('fxFeeTiers', i)} className='fa fa-trash-o static font-size-14 text-default'></i>}
                                </div>
                            ))}
                            <div className='row'>
                                <button className='btn btn-link no-pdd' onClick={(e) => handleAddTier('fxFeeTiers', e)} disabled={false}>+ Add tier</button>
                            </div>
                        </TiersWrapper>
                        <hr />
                        <div className='row mrg-btm-8'>
                            <div className='col-md-12'>
                                <div className='form-group percents'>
                                    <label htmlFor='txFee'>TX fee: *</label>
                                    <Input type='text' className='form-control number' id='txFee' name='txFee'  validations={[required, percentFee]} value={formValues.txFee !==  undefined ? feeMultiplied(formValues.txFee) : ''} onBlur={handleChange} />
                                    <i className='percents-icon'>%</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <h4 className='mrg-btm-20'>Conversion fees</h4>
                        <label className="width-100" htmlFor='fxFeeTier0'>Conversion fee tiers: * </label>
                        <TiersWrapper className='no-mrg mrg-btm-10 mrg-top-5 mrg-left-15'>
                            {conversionFeeTiersMap.map((tier, i) => (
                                <div className='row pdd-right-15 no-pdd-sm-screen' key={`conversionFeeTiersMap${i}`}>
                                    <label className='mrg-right-15 tier-label'>Tier {i+1}: </label>
                                    {!reload && <div className='empty tier-group-item from'>{i === 0 ? <span className='text'>0</span> : <Input data-max={conversionFeeTiersMap[i+1] && conversionFeeTiersMap[i-1].from ? parseFloat(conversionFeeTiersMap[i+1].from) + 1 : undefined} data-min={conversionFeeTiersMap[i-1] && conversionFeeTiersMap[i-1].from ? parseFloat(conversionFeeTiersMap[i-1].from) + 1 : undefined}  data-percent={false} type='text' className='form-control number' id={`conversionFeeTiers`} name={`conversionFeeTiers[${i}].from`} value={tier.from !== 0 ? tier.from : 0} validations={[required, minValue]} onBlur={(e) => handleFromInput(e, i)}  />}</div>}
                                    <span className='separate'>-</span>
                                    <div className='empty tier-group-item'>{i === formValues.conversionFeeTiers.length -1 ? <span className='text'>&#8734;</span> : <span className='text'>{tier.to} EUR</span>}</div>
                                    <span className='separate'></span>
                                    <div className='empty tier-group-item form-group'><Input type='text' className='form-control number percent' id={`conversionFeeTiers`} name={`conversionFeeTiers`} validations={[required, percentFee, minValue]} data-min={0}  value={tier.fee ? feeMultiplied(tier.fee) : tier.fee === 0 ? feeMultiplied(0) : ''} onBlur={(e) => handleTierInput(e, i)} /><i className='percents-icon top-0'>%</i></div>
                                    {i !== 0 && <i onClick={() => handleDeleteTier('conversionFeeTiers', i)} className='fa fa-trash-o static font-size-14 text-default'></i>}
                                </div>
                            ))}
                            <div className='row'>
                                <button className='btn btn-link no-pdd' onClick={(e) => handleAddTier('conversionFeeTiers', e)} disabled={false}>+ Add tier</button>
                            </div>
                        </TiersWrapper>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className='col-md-12'>
                        <h4 className='mrg-btm-20'>Payout fees</h4>
                        <div className='row no-mrg mrg-right-minus-15 mrg-btm-12'>
                            <label className='width-100' htmlFor='payoutFxFeeTiers'>Payout FX fee tiers: *</label>
                            <TiersWrapper className='no-mrg mrg-btm-10 mrg-top-5 mrg-left-15'>
                                {payoutFxFeeTiersMap.map((tier, i) => (
                                    <div className='row pdd-right-15 no-pdd-sm-screen' key={`payoutFxFeeTiers${i}`}>
                                        <label className='mrg-right-15 tier-label'>Tier {i+1}: </label>
                                        {!reload && <div className='empty tier-group-item from'>{i === 0 ? <span className='text'>0</span> : <Input data-max={payoutFxFeeTiersMap[i+1] && payoutFxFeeTiersMap[i-1].from ? parseFloat(payoutFxFeeTiersMap[i+1].from) + 1 : undefined} data-min={payoutFxFeeTiersMap[i-1] && payoutFxFeeTiersMap[i-1].from ? parseFloat(payoutFxFeeTiersMap[i-1].from) + 1 : undefined}  data-percent={false} type='text' className='form-control number' id={`payoutFxFeeTiers`} name={`payoutFxFeeTiers[${i}].from`} value={tier.from !== 0 ? tier.from : 0} validations={[required, minValue]} onBlur={(e) => handleFromInput(e, i)}  />}</div>}
                                        <span className='separate'>-</span>
                                        <div className='empty tier-group-item'>{i === formValues.payoutFxFeeTiers.length -1 ? <span className='text'>&#8734;</span> : <span className='text'>{tier.to} EUR</span>}</div>
                                        <span className='separate'></span>
                                        <div className='empty tier-group-item form-group'><Input type='text' className='form-control number percent' id={`payoutFxFeeTiers`} name={`payoutFxFeeTiers`} validations={[required, percentFee]}  value={tier.fee ? feeMultiplied(tier.fee) : tier.fee === 0 ? feeMultiplied(0) : ''} onBlur={(e) => handleTierInput(e, i)} /><i className='percents-icon top-0'>%</i></div>
                                        {i !== 0 && <i onClick={() => handleDeleteTier('payoutFxFeeTiers', i)} className='fa fa-trash-o static font-size-14 text-default'></i>}
                                    </div>
                                ))}
                                <div className='row'>
                                    <button className='btn btn-link no-pdd' onClick={(e) => handleAddTier('payoutFxFeeTiers', e)} disabled={false}>+ Add tier</button>
                                </div>
                            </TiersWrapper>
                        </div>
                        <div className='row no-mrg mrg-right-minus-15 mrg-btm-12'>
                            <label className='width-100' htmlFor='payoutNetworkFeeTiers'>Payout network fee tiers: *</label>
                            <TiersWrapper className='no-mrg mrg-btm-10 mrg-top-5 mrg-left-15'>
                                {payoutNetworkFeeTiersMap.map((tier, i) => (
                                    <div className='row pdd-right-15 no-pdd-sm-screen' key={`payoutNetworkFeeTiers${i}`}>
                                        <label className='mrg-right-15 tier-label'>Tier {i+1}: </label>
                                        {!reload && <div className='empty tier-group-item from'>{i === 0 ? <span className='text'>0</span> : <Input data-max={payoutNetworkFeeTiersMap[i+1] && payoutNetworkFeeTiersMap[i-1].from ? parseFloat(payoutNetworkFeeTiersMap[i+1].from) + 1 : undefined} data-min={payoutNetworkFeeTiersMap[i-1] && payoutNetworkFeeTiersMap[i-1].from ? parseFloat(payoutNetworkFeeTiersMap[i-1].from) + 1 : undefined}  data-percent={false} type='text' className='form-control number' id={`payoutNetworkFeeTiers`} name={`payoutNetworkFeeTiers[${i}].from`} value={tier.from !== 0 ? tier.from : 0} validations={[required, minValue]} onBlur={(e) => handleFromInput(e, i)}  />}</div>}
                                        <span className='separate'>-</span>
                                        <div className='empty tier-group-item'>{i === formValues.payoutNetworkFeeTiers.length -1 ? <span className='text'>&#8734;</span> : <span className='text'>{tier.to} EUR</span>}</div>
                                        <span className='separate'></span>
                                        <div className='empty tier-group-item form-group'><Input type='text' className='form-control number percent' id={`payoutNetworkFeeTiers`} name={`payoutNetworkFeeTiers`} validations={[required, percentFee]} data-percent-limit={false}  value={tier.fee ? feeMultiplied(tier.fee) : tier.fee === 0 ? feeMultiplied(0) : ''} onBlur={(e) => handleTierInput(e, i)} /><i className='percents-icon top-0'>%</i></div>
                                        {i !== 0 && <i onClick={() => handleDeleteTier('payoutNetworkFeeTiers', i)} className='fa fa-trash-o static font-size-14 text-default'></i>}
                                    </div>
                                ))}
                                <div className='row'>
                                    <button className='btn btn-link no-pdd' onClick={(e) => handleAddTier('payoutNetworkFeeTiers', e)} disabled={false}>+ Add tier</button>
                                </div>
                            </TiersWrapper>
                        </div>
                        <div className='row mrg-btm-8'>
                            <div className='col-md-12'>
                                <div className='form-group percents'>
                                    <label htmlFor='payoutTxFee'>Payout TX fee: *</label>
                                    <Input type='text' className='form-control number' id='payoutTxFee' name='payoutTxFee'  validations={[required, percentFee]} value={formValues.payoutTxFee !==  undefined ? feeMultiplied(formValues.payoutTxFee) : ''} onBlur={handleChange} />
                                    <i className='percents-icon'>%</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>       
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p>* - Field is required</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <button type='button' className='btn btn-default width-100' data-dismiss='modal'>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        {disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled width-100' type='submit' disabled>Update fees</button>
                        ) : (
                            <Button className='btn btn-primary pull-right width-100' type='submit'>Update fees</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
}

export default EditFeeModal;